<div class="products">
    <div class="title">
        <div class="tit">
            <h3>RENDELÉSEK (előző heti)</h3>
            <mat-form-field>
                <mat-select [(value)]="selected">
                    <mat-option (click)="reload()" value="Vissza az előző heti rendelésekhez">
                        <button [ngStyle]="{ border: 'none', background: 'none', 'text-transform': 'uppercase', 'text-align': 'start' }">
                            Vissza az előző heti rendelésekhez
                        </button>
                    </mat-option>
                    <mat-option routerLink="/orderdetailsforamonth" value="Előző 1 havi rendelési összesítő">
                        <button [ngStyle]="{ border: 'none', background: 'none', 'text-transform': 'uppercase' }">
                            Előző 1 havi rendelési összesítő
                        </button>
                    </mat-option>
                    <mat-option (click)="searchAboutOnChangeOnOrderReference()" value=" Keresés rendelési azonosító alapján">
                        <button [ngStyle]="{ border: 'none', background: 'none', 'text-transform': 'uppercase' }">
                            Keresés rendelési azonosító alapján
                        </button>
                    </mat-option>
                    <mat-option (click)="searchAboutOnChangeOnDates()" value="Keresés dátum alapján">
                        <button [ngStyle]="{ border: 'none', background: 'none', 'text-transform': 'uppercase' }">
                            Keresés dátum alapján
                        </button>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="add-new-product">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Rendelési azonosító" [disabled]="!isSearchAboutOrderReference" />
                <div class="field-container">
                    <div class="field">
                        <div class="data">
                            <input
                                #startDateElementInput
                                [(ngModel)]="startDateElement"
                                id="startDate"
                                class="form-control"
                                type="date"
                                placeholder="Kezdő dátum"
                                required
                                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                title="Enter a date in this formart YYYY-MM-DD"
                                aria-required="true"
                                [disabled]="isSearchAboutOrderReference"
                            />
                        </div>
                    </div>
                </div>
                <div class="field-container">
                    <div class="field">
                        <div class="data">
                            <input
                                #endDateElementInput
                                [(ngModel)]="endDateElement"
                                id="endDate"
                                class="form-control"
                                type="date"
                                placeholder="Befejező dátum"
                                required
                                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                title="Enter a date in this formart YYYY-MM-DD"
                                required
                                [disabled]="isSearchAboutOrderReference"
                            />
                        </div>
                    </div>
                </div>
                <!-- <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div> -->
                <div class="search-button" [ngStyle]="{ display: 'flex', background: '#ccc', padding: '16px' }">
                    <button (click)="findOrderBy()" [ngStyle]="{ border: 'none', height: '100%', background: 'none', cursor: 'pointer' }">
                        <b>Keresés az előzményekben</b>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="productss">
        <div class="titles">
            <div class="pro">
                <div class="author">
                    <div [ngStyle]="{ background: '#2C3333', color: 'white' }" class="datas">
                        <div class="data">
                            <span>DÁTUM</span>
                        </div>
                        <div class="data">
                            <span>RENDELÉSI AZONOSÍTÓ</span>
                        </div>
                        <div class="data">
                            <span>NÉV</span>
                        </div>
                        <div class="data">
                            <span>EMAIL CÍM</span>
                        </div>
                        <div class="data">
                            <span>ELŐRENDELÉST TARTALMAZ?</span>
                        </div>
                        <div class="data">
                            <span>VÉGÖSSZEG</span>
                        </div>
                        <div class="data">
                            <span>KIFIZETÉS STÁTUSZA</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="products-c">
            <div *ngFor="let order of filteredOrder; let i = index" class="product">
                <div class="pro">
                    <div class="author">
                        <div
                            (click)="findOrderDetailsByOrderId(order.id!)"
                            [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                            class="datas"
                        >
                            <div class="data">
                                <span>{{ order.orderDate | date : 'yyyy, MMM dd, hh:mm a' }}</span>
                            </div>
                            <div class="data">
                                <span [ngStyle]="{ 'font-weight': 'bold' }">{{ order.orderReference }}</span>
                            </div>
                            <div class="data">
                                <span>{{ order.name }}</span>
                            </div>
                            <div class="data">
                                <span>{{ order.email }}</span>
                            </div>
                            <div class="data">
                                <span>{{ 'preorder.' + order.hasPreorder | translate }}</span>
                            </div>
                            <div class="data">
                                <span>{{ order.totalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                            </div>
                            <div class="data">
                                <b
                                    *ngIf="order.paymentOption === 'SIMPLE' && order.failedPaymentMessageKey !== null"
                                    [ngStyle]="{ color: 'red' }"
                                    >{{ 'payment.' + order.failedPaymentMessageKey | translate }}</b
                                >
                            </div>
                            <!-- <div class="data-icon">
                                <div class="failed-payment">
                                    <span *ngIf="order.paymentOption === 'SIMPLE'">{{
                                        'payment.' + order.failedPaymentMessageKey | translate
                                    }}</span>
                                </div>
                                <mat-icon *ngIf="order.paymentOption === 'SIMPLE'">info</mat-icon>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="admin-table-container">
    <div class="title">
        <div class="tit">
            <h3>RENDELÉSEK</h3>
            <!-- <div class="add-icon">
                <button routerLink="/order">Összes rendelési tétel</button>
            </div> -->
        </div>
    </div>
    <div class="add-new-product">
        <div class="search-bar">
            <div [ngStyle]="{ 'flex-direction': 'column' }" class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Rendelési azonosító" />
                <!-- <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div> -->

                <div class="field-container">
                    <div class="field">
                        <div class="data">
                            <input
                                #startDateElementInput
                                [ngStyle]="{ padding: '0', 'margin-top': '8px', 'margin-bottom': '8px' }"
                                id="startDate"
                                [(ngModel)]="startDateElement"
                                class="form-control"
                                type="date"
                                placeholder="Kezdő dátum"
                                required
                                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                title="Enter a date in this formart YYYY-MM-DD"
                                aria-required="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="field-container">
                    <div class="field">
                        <div class="data">
                            <input
                                #endDateElementInput
                                [ngStyle]="{ padding: '0', 'margin-top': '8px', 'margin-bottom': '8px' }"
                                id="endDate"
                                [(ngModel)]="endDateElement"
                                class="form-control"
                                type="date"
                                placeholder="Befejező dátum"
                                required
                                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                title="Enter a date in this formart YYYY-MM-DD"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div class="search-button" [ngStyle]="{ display: 'flex', background: '#ccc', padding: '8px' }">
                    <button (click)="findOrderBy()" [ngStyle]="{ border: 'none', height: '100%', background: 'none', cursor: 'pointer' }">
                        <b>Keresés az előzményekben</b>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div
        [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
        *ngFor="let order of filteredOrder; let i = index"
        class="admin-table"
    >
        <div class="field-container">
            <div class="field">
                <span>Rendelés dátuma</span>
                <div class="data">
                    <span>{{ order.orderDate | date : 'yyyy, MMM dd, hh:mm a' }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Rendelési azonosító</span>
                <div class="data">
                    <span [ngStyle]="{ 'font-weight': 'bold' }">{{ order.orderReference }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Név</span>
                <div class="data">
                    <span>{{ order.name }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Email cím</span>
                <div class="data">
                    <span>{{ order.email }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Végösszeg</span>
                <div class="data">
                    <span>{{ order.totalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Kifizetés státusza</span>
                <div class="data">
                    <b *ngIf="order.failedPaymentMessageKey !== null" [ngStyle]="{ color: 'red' }">{{
                        'payment.' + order.failedPaymentMessageKey | translate
                    }}</b>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Rendelési tételek</span>
                <div class="data">
                    <button routerLink="{{ order.id }}">Rendelési tételek</button>
                </div>
            </div>
        </div>
    </div>
</div>
