<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/products">arrow_back</mat-icon>
    </div>
    <div>
        <h3>ÚJ TERMÉK HOZZÁADÁSA</h3>
    </div>
    <div class="back-to-books">
        <span
            >A <span [ngStyle]="{ color: 'red', width: '16px', height: '16px', 'font-size': '16px' }">*</span> -al jelölt mezők kitöltése
            kötelező!</span
        >
    </div>
    <div class="contact-form">
        <form [formGroup]="productForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Szerző <span class="star">*</span></span>
                    <div class="data">
                        <input id="author" formControlName="author" class="form-control" type="text" required />
                        <div
                            *ngIf="
                                productForm!.controls['author'].invalid &&
                                (productForm!.controls['author'].dirty || productForm!.controls['author'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['author'].errors?.['required']">
                                <span>{{ 'admin.authorRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Cím <span class="star">*</span></span>
                    <div class="data">
                        <input id="title" formControlName="title" class="form-control" type="text" required />
                        <div
                            *ngIf="
                                productForm!.controls['title'].invalid &&
                                (productForm!.controls['title'].dirty || productForm!.controls['title'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['title'].errors?.['required']">
                                <span>{{ 'admin.titleRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Könyvön szereplő ár <span class="star">*</span></span>
                    <span
                        [ngStyle]="{ color: 'red', 'font-size': '12px' }"
                        class="text-danger"
                        *ngIf="productForm!.get('originalPrice')!.hasError('pattern')"
                    >
                        Az ár csak számot tartalmazhat!
                    </span>
                    <div class="data">
                        <input id="originalPrice" formControlName="originalPrice" class="form-control" type="number" required />
                        <div
                            *ngIf="
                                productForm!.controls['originalPrice'].invalid &&
                                (productForm!.controls['originalPrice'].dirty || productForm!.controls['originalPrice'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['originalPrice'].errors?.['required']">
                                <span>{{ 'admin.originalPriceRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span
                        >Oldalszám
                        <span class="star">*</span>
                    </span>
                    <div class="data">
                        <input formControlName="content" id="content" class="form-control" type="text" required />
                        <div
                            *ngIf="
                                productForm!.controls['content'].invalid &&
                                (productForm!.controls['content'].dirty || productForm!.controls['content'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['content'].errors?.['required']">
                                <span>{{ 'admin.contentRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>ISBN <span class="star">*</span></span>
                    <div class="data">
                        <input id="isbn" formControlName="isbn" class="form-control" type="number" required />
                        <div
                            *ngIf="
                                productForm!.controls['isbn'].invalid &&
                                (productForm!.controls['isbn'].dirty || productForm!.controls['isbn'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['isbn'].errors?.['required']">
                                <span>{{ 'admin.isbnRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Megjelenési dátum <span class="star">*</span></span>
                    <div class="data">
                        <input
                            id="available"
                            formControlName="available"
                            class="form-control"
                            type="date"
                            placeholder="YYYY-MM-DD"
                            required
                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                            title="Enter a date in this formart YYYY-MM-DD"
                            required
                        />
                        <div
                            *ngIf="
                                productForm!.controls['available'].invalid &&
                                (productForm!.controls['available'].dirty || productForm!.controls['available'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['available'].errors?.['required']">
                                <span>{{ 'admin.availableRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Kötés <span class="star">*</span></span>
                    <div class="data">
                        <input id="bond" formControlName="bond" class="form-control" type="text" required />
                        <div
                            *ngIf="
                                productForm!.controls['bond'].invalid &&
                                (productForm!.controls['bond'].dirty || productForm!.controls['bond'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['bond'].errors?.['required']">
                                <span>{{ 'admin.bondRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>Méret <span class="star">*</span></span>
                    <div class="data">
                        <input id="dimension" formControlName="dimension" class="form-control" type="text" required />
                        <div
                            *ngIf="
                                productForm!.controls['dimension'].invalid &&
                                (productForm!.controls['dimension'].dirty || productForm!.controls['dimension'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['dimension'].errors?.['required']">
                                <span>{{ 'admin.dimensionRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Készlet <span class="star">*</span></span>
                    <div class="data">
                        <input id="quantityofsupply" formControlName="quantityofsupply" class="form-control" type="number" required />
                        <div
                            *ngIf="
                                productForm!.controls['quantityofsupply'].invalid &&
                                (productForm!.controls['quantityofsupply'].dirty || productForm!.controls['quantityofsupply'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['quantityofsupply'].errors?.['required']">
                                <span>{{ 'admin.quantityOfSupplyRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="form-not-required">
            <div class="field-container">
                <div class="field">
                    <span>Megjelent? </span>
                    <div class="data">
                        <mat-checkbox
                            (change)="onChangeIsAvailable()"
                            [ngModel]="isAvailable"
                            #isAvailableInput
                            id="isAvailable"
                            class="form-control"
                            type="checkbox"
                            required
                            [disabled]="hasPreorder"
                        ></mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>Online ár </span>
                    <div class="data">
                        <input (change)="onChangeWebshopPrice()" #webshopPriceInput class="form-control" type="number" required />
                        <span>Aktív?</span>
                        <div class="data">
                            <mat-checkbox
                                [ngModel]="webshopPriceActive"
                                (change)="onChangeWebshopPriceActive()"
                                #webshopPriceActiveInput
                                id="webshopPriceActive"
                                class="form-control"
                                type="checkbox"
                                required
                            ></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Előrendelői ár</span>
                    <div class="data">
                        <input
                            id="preorderPrice"
                            (change)="onChangePreorderPrice()"
                            #preorderPriceInput
                            class="form-control"
                            type="number"
                            required
                        />
                        <span>Aktív?</span>
                        <div class="data">
                            <mat-checkbox
                                [ngModel]="hasPreorder"
                                (change)="onChangePreorderPriceActive()"
                                #hasPreorderInput
                                id="hasPreorder"
                                class="form-control"
                                type="checkbox"
                                required
                            ></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Akciós ár</span>
                    <div class="data">
                        <input
                            (change)="onChangeDiscountPrice()"
                            #discountPriceInput
                            id="discountPrice"
                            class="form-control"
                            type="number"
                            required
                        />
                        <span>Aktív? </span>
                        <div class="data">
                            <mat-checkbox
                                [ngModel]="discountPriceActive"
                                (change)="onChangeDiscountPriceActive()"
                                #discountPriceActiveInput
                                id="discountPriceActive"
                                class="form-control"
                                type="checkbox"
                                required
                            ></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Csomagár</span>
                    <div class="data">
                        <input
                            id="packagePrice"
                            (change)="onChangePackagePrice()"
                            #packagePriceInput
                            class="form-control"
                            type="number"
                            required
                        />
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>Fordító</span>
                    <div class="data">
                        <input (change)="onChangeTranslator()" #translatorInput id="translator" class="form-control" type="text" required />
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>Illusztrátor</span>
                    <div class="data">
                        <input
                            (change)="onChangeIllustrator()"
                            #illustratorInput
                            id="illustrator"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Alcím</span>
                    <div class="data">
                        <input (change)="onChangeSubtitle()" #subtitleInput id="subtitle" class="form-control" type="text" />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Fülszöveg *</span>
                    <div class="data">
                        <app-tinymcehtmleditor
                            [html]="html"
                            [htmlForm]="htmlForm"
                            id="html"
                            class="form-control"
                            type="text"
                            required
                            ngDefaultControl
                        ></app-tinymcehtmleditor>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Borítókép (.png, maximum méret: 5MB) <span class="star">*</span></span>
                    <div class="data">
                        <div [ngStyle]="{ display: 'flex', gap: '8px' }">
                            <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('file').click()">
                                Borítókép kiválasztása
                            </button>
                            <span *ngIf="addCoverFile">{{ fileCover?.name }}</span>
                            <span *ngIf="!addCoverFile">Nincs borítókép kiválasztva</span>
                            <mat-icon (click)="clearSelectedCoverFile()">delete_forever</mat-icon>
                            <input
                                [ngStyle]="{ display: 'none' }"
                                id="file"
                                #fileCoverInput
                                class="form-control"
                                type="file"
                                maxFileSize="5000000"
                                (change)="onChangeCover($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Beleolvasó (.pdf)</span>
                    <div class="data">
                        <div [ngStyle]="{ display: 'flex', gap: '8px' }">
                            <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('fileBookExtract').click()">
                                Beleolvasó kiválasztása
                            </button>
                            <span *ngIf="addBookExtractFile">{{ fileBookExtract?.name }}</span>
                            <span *ngIf="!addBookExtractFile">Nincs beleolvasó kiválasztva</span>
                            <input
                                id="fileBookExtract"
                                #fileBookExtractInput
                                class="form-control"
                                type="file"
                                (change)="onChangeBookExtract($event)"
                                [ngStyle]="{ display: 'none' }"
                            />
                            <mat-icon (click)="clearSelectedBookExtractFile()">delete_forever</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngStyle]="{ 'margin-top': '8px', 'border-bottom': '2px solid black' }" class="field-container">
                <div class="field-addproduct">
                    <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                        <button class="addproduct" (click)="addProductWithFiles()" [disabled]="!productForm?.valid || !htmlForm?.valid">
                            Termék hozzáadása
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
