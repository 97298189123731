import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, HttpError, TranslationService } from '@prekog/pw-common';
import { Package } from '../package';
import { UploadFileResponse } from '../../products/addproduct/uploadfileresponse';
import { Router } from '@angular/router';
import { catchError, throwError, take, map, forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AddPackageRequest } from './addpackagerequest';
import { AddPackageResponse } from './addpackageresponse';
import { PackageProduct } from '../addproductstopackage/packageproduct';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { Material } from '../../products/material';
import { CreatePackageProductRequest } from '../addproductstopackage/createpackageproductrequest';
import { CreatePackageProductResponse } from '../addproductstopackage/createpackageproductresponse';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AddpackagepricepopupComponent } from './addpackagepricepopup/addpackagepricepopup.component';
import { AddPackagePriceRequest } from './addpackagepricepopup/addpackagepricerequest';

@Component({
    selector: 'app-addpackage',
    templateUrl: './addpackage.component.html',
    styleUrls: ['./addpackage.component.scss']
})
export class AddpackageComponent implements OnInit {
    file: File | null = null;

    uploadFileUrl = '/admin/uploadFile';

    fileBaseUrl = '';

    storeBaseUrl = '';

    packageForm?: FormGroup;

    uploadFileResponse: UploadFileResponse = {};

    downloadUri = '';

    url = '';

    package: Package = {};

    errorMessage = 'Kérem töltse fel a borítóképet és fülszöveget a termék hozzáadása előtt!';

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    addCoverFile = false;

    storeExistingProductsURI = '/store/get-products';

    addPackageRequest: AddPackageRequest = {};

    addPackageResponse: AddPackageResponse = {};

    addPackageUrl = '/admin/addpackage';

    products: Material[] = [];

    filteredBooks: Material[] = [];

    packageProductList: Material[] = [];

    createPackageProductRequest: CreatePackageProductRequest = {};

    createPackageProductUrl = '/admin/createpackageproduct';

    maxFileSize = 5000000;

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredBooks = this.performFilter(value);
    }

    isExtraSmall: Observable<BreakpointState>;
    isSmall: Observable<BreakpointState>;
    isMedium: Observable<BreakpointState>;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';

    sum = 0;

    constructor(
        private _router: Router,
        private _fb: FormBuilder,
        private _toastMessageService: ToastmessageService,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _translateService: TranslationService,
        private readonly breakpointObserver: BreakpointObserver,
        private _matDialog: MatDialog
    ) {
        this.fileBaseUrl = _fileManagerUrl;
        this.storeBaseUrl = _storeBaseUrl;
        this.createPPackageForm();
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
        this.isSmall = this.breakpointObserver.observe(Breakpoints.Small);
        this.isMedium = this.breakpointObserver.observe(Breakpoints.Medium);
    }

    performFilter(filterBy: string): Material[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.products.filter(
            (publication: Material) =>
                publication.title?.toLocaleLowerCase().includes(filterBy) || publication.author?.toLocaleLowerCase().includes(filterBy)
        );
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        if (sessionStorage.getItem('product')) {
            const product = JSON.parse(sessionStorage.getItem('product') as string) as Material;
            const index = this.products.indexOf(product);
            this.products.splice(index, 1);
            this.packageProductList.push(product);
            sessionStorage.removeItem('product');

        }
        this._apiRequestService.sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI).subscribe((response) => {
            const products = response as GetProductsResponse;
            this.products = products.productList as Material[];
            this.filteredBooks = this.products;

        });
    }

    createPPackageForm() {
        this.packageForm = this._fb.group({
            packageName: ['', [Validators.required]],
            imageUrl: ['', [Validators.required]]
        });
    }

    onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'png') {
            this.addCoverFile = true;
        }
        else {
            this.addCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".png" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    addPackage(packagee: Package) {
        //TODO call file manager to get downloadUri
        if (!this.addCoverFile) {
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem válassza ki a borítóképet!', MAT_ICON.warning);
            this.dismissError();
        }
        else if (this.packageProductList.length === 0) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.warning,
                'Kérem adjon hozzá könyveket a csomaghoz!!',
                MAT_ICON.warning
            );
            this.dismissError();
        }
        else if (this.fileCover?.size! > this.maxFileSize) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
        } else {
            this._apiRequestService
                .uploadFile(this.fileCover!,true, true, this.fileBaseUrl, this.uploadFileUrl)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;
                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();
                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this.uploadFileResponse = response as UploadFileResponse;
                    this.downloadUri = this.uploadFileResponse.downloadUri as string;
                    if (this.uploadFileResponse.downloadUri) {
                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.success,
                            'Sikeres fájl feltöltés ' + this.uploadFileResponse.fileName!,
                            MAT_ICON.success
                        );
                        this.dismissError();
                        this.url = this.downloadUri;

                        this.addCoverFile = false;
                        this.collectPackageData(packagee);
                        this.package.imageUrl = this.downloadUri;
                        this.addPackageRequest = {
                            packageObject: this.package
                        };

                        this._apiRequestService
                            .sendRequest<AddPackageRequest, AddPackageResponse>(
                                this.storeBaseUrl,
                                true,
                                this.addPackageRequest,
                                true,
                                this.addPackageUrl
                            )
                            .pipe(
                                catchError((error: HttpErrorResponse) => {
                                    return throwError(() => {
                                        const httpError: HttpError = error.error as HttpError;

                                        this._toastMessageService.displayToastMessage(
                                            TOAST_STATE.error,
                                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                            MAT_ICON.error
                                        );
                                        this.dismissError();

                                        this._router.navigateByUrl('/');
                                    });
                                })
                            )
                            .subscribe((response) => {
                                //TODO separate success message from error message!!!
                                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.message!, MAT_ICON.success);
                                this.dismissError();
                                this.package.routerLink = response.routerLink;

                                let packageProductList: PackageProduct[] = [];
                                this.packageProductList.forEach((productsToPackage) => {
                                    const packageProduct: PackageProduct = new PackageProduct();
                                    packageProduct.productId = productsToPackage.id;
                                    packageProduct.packageObject = this.package;
                                    packageProductList.push(packageProduct);
                                });

                                this.createPackageProductRequest = {
                                    packageProduct: packageProductList
                                };

                                this._apiRequestService
                                    .sendRequest<CreatePackageProductRequest, CreatePackageProductResponse>(
                                        this.storeBaseUrl,
                                        true,
                                        this.createPackageProductRequest,
                                        true,
                                        this.createPackageProductUrl
                                    )
                                    .pipe(
                                        catchError((error: HttpErrorResponse) => {
                                            return throwError(() => {
                                                const httpError: HttpError = error.error as HttpError;

                                                this._toastMessageService.displayToastMessage(
                                                    TOAST_STATE.error,
                                                    this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                                    MAT_ICON.error
                                                );
                                                this.dismissError();

                                                this._router.navigateByUrl('/');
                                            });
                                        })
                                    )
                                    .subscribe((responseMessage) => {
                                        this._toastMessageService.displayToastMessage(
                                            TOAST_STATE.success,
                                            responseMessage.responseMessage!,
                                            MAT_ICON.success
                                        );
                                        this.dismissError();
                                        this._router.navigateByUrl('/packages');
                                    });
                            });
                    }
                });
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    private dismissErrorFiveSeconds(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 5000);
    }

    collectPackageData(packagee: Package) {
        packagee = {
            packageName: this.packageForm?.controls['packageName'].value as string | undefined
        };

        if (this.url) {
            this.package.imageUrl = this.url;
        }
    }

    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.addCoverFile = false;
    }

    addProductToPackage(product: Material) {
        this.sum += product.packagePrice!;
        if (!product.packagePrice) {
            this.openAddPackegPricePopup(product);
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('Kérem adja meg a csomagárat a ' + product.author + ':' + product.title + ' könyvhöz!'),
                MAT_ICON.error
            );
            this.dismissErrorFiveSeconds();
        } else {
            const index = this.products.indexOf(product);
            this.products.splice(index, 1);
            this.packageProductList.push(product);
        }
    }

    deleteFromPackageProductList(product: Material) {
        this.sum -= product.packagePrice!;
        const index = this.packageProductList.indexOf(product);
        this.packageProductList.splice(index, 1);
        this.products.push(product);
    }

    openAddPackegPricePopup(product: Material) {
        const popupDialog = this._matDialog.open(AddpackagepricepopupComponent, {
            data: product,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }
}
