import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiRequestService, ToastmessageService } from '@prekog/pw-common';
import { ConfirmStatusPopupComponent } from '../confirm-status-popup/confirm-status-popup.component';
import { ExportToExcelRequest } from './export-to-excel-request';
import { GetOrdersRequest } from './get-orders-request.model';
import { GetOrdersResponse } from './get-orders-response.model';
import { GetProductRequest } from './get-product-request';
import { GetProductResponse } from './get-product-response';
import { GetOrderedStoreProductsRequest } from './getorderedstoreproductsrequest';
import { GetOrderedStoreProductsResponse } from './getorderedstoreproductsresponse';
import { GetOrderReferenceRequest } from './getorderreferencerequest';
import { GetOrderReferenceResponse } from './getorderreferenceresponse';
import { OrderExportToExcel } from './order-export-to-excel';
import { OrderProduct } from './order-product.model';
import { OrderWithStatusOption } from './order-with-statusoption';
import { OrderDetails } from './order.model';
import { Product } from './product.model';
import { StatusOption } from './statusoption';
import { StoreOrderedProduct } from './storeorderproduct';

@Component({
    selector: 'pw-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
    @ViewChild('customerOrderReference', { static: true }) target!: ElementRef;

    GET_ORDERS_URI = '/admin/get-orders';

    GET_PREVIOUS_MONTH_ORDERDETAILS = '/admin/get-previous-month-orderdetails';

    GET_SEPARATED_VALUE_URI = '/admin/isorderseparated';

    GET_ORDEREDPRODUCTS_URI = '/admin/get-orderedproducts';

    GET_ORDERED_STOREPRODUCTS_URI = '/admin/get-ordered-storeproducts';

    index = 0;

    isProductScreenVisible = false;

    optionDisabled = false;

    orders: OrderDetails[] = [];

    filteredOrder: OrderDetails[] = [];

    finishedOrders: OrderDetails[] = [];

    unfinishedOrders: OrderDetails[] = [];

    preOrderedOrders: OrderDetails[] = [];

    actualFilteringOrders: OrderDetails[] = [];

    orderedProductList: Product[] = [];

    orderStatusOptionList = [
        StatusOption.WAITING_FOR_PAYMENT,
        StatusOption.WAITING_FOR_PROCESSING,
        StatusOption.WAITING_FOR_PUBLICATION,
        StatusOption.PAYMENT_FAILED,
        StatusOption.UNDER_PACKING,
        StatusOption.UNDER_DELIVERY,
        StatusOption.BLOCKED,
        StatusOption.FINISHED,
        StatusOption.DELETED
    ];

    typeOfOrder = 'UNFINISHED';

    displayFinished = false;
    displayUnFinished = true;
    displayAllOrders = false;
    displayPreorders = false;

    order: OrderDetails = {};

    orderStatusStorePickupOptionList = [
        StatusOption.WAITING_FOR_PAYMENT,
        StatusOption.WAITING_FOR_PROCESSING,
        StatusOption.WAITING_FOR_PUBLICATION,
        StatusOption.PAYMENT_FAILED,
        StatusOption.BLOCKED,
        StatusOption.RECEIVABLE,
        StatusOption.FINISHED,
        StatusOption.DELETED
    ];

    productList: Product[] = [];

    productListForExcel: Product[] = [];

    productMap: Record<number, Product> = {};

    UPDATE_ORDER_STATUS_URI = '/order/update-status';

    EXPORT_TO_EXCEL_URI = '/order/export-to-excel';

    orderBackendUrl = '';

    isFinished = false;

    getProductRequest: GetProductRequest = {};

    exportToExcelRequest?: ExportToExcelRequest = {};

    exportToExcelOrder?: OrderExportToExcel[] = [];

    private _listFilter = '';

    fileUrl: SafeResourceUrl = {};

    orderWithStatusOptionList?: OrderWithStatusOption[] = [];

    previousOrderStatus = '';

    storeBackendUrl = '';

    getOrderReferenceRequest: GetOrderReferenceRequest = {};

    getOrderReferenceResponse: GetOrderReferenceResponse = {};

    getOrderedStoreProductsRequest: GetOrderedStoreProductsRequest = {};

    getOrderedStoreProductsResponse: GetOrderedStoreProductsResponse = {};

    storeOrderedProductsList: StoreOrderedProduct[] = [];

    orderProductListForrequest:OrderProduct[] = [];

    selected='Befejezetlen e havi rendelések';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredOrder = this.performFilter(value);
        // this.finishedOrders = this.performFilter(value);
        // this.unfinishedOrders = this.performFilter(value);
        // this.preOrderedOrders = this.performFilter(value);
    }

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        private _matDialog: MatDialog,
        private readonly breakpointObserver: BreakpointObserver,
        private _toastMessageService: ToastmessageService
    ) {
        this.orderBackendUrl = _orderBackendUrl;
        this.storeBackendUrl = _storeBackendUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<GetOrdersRequest, GetOrdersResponse>(this.orderBackendUrl, true, {}, true, this. GET_PREVIOUS_MONTH_ORDERDETAILS )
                .subscribe((response: GetOrdersResponse) => {
                    this.orders = response.orderList;
                    this.orders.forEach((order, index) => {
                        order.previousStatus = order.status;
                        //allOrdersList
                        if (order.taxNumber) {
                            this.index = index;
                        }
                        if (order.status === 'FINISHED') {
                            this.finishedOrders.push(order);
                        }
                        if (order.preOrder && order.status !== 'FINISHED') {
                            this.preOrderedOrders.push(order);
                        }
                        if (order.status !== 'FINISHED') {
                            this.unfinishedOrders.push(order);
                        }
                        const date = order.insertedTimestamp?.toString();
                        const splittedDate = date?.split(',');
                        order.insertedTimestampp = splittedDate![0] + '.' + splittedDate![1] + '.' + splittedDate![2];
                        this.filteredOrder = this.unfinishedOrders;
                    });
                });
        } else if (!sessionStorage.getItem('token')) {
            void this._router.navigateByUrl('/');
        }
    }

    getProducts(order: OrderDetails) {
        let orderProductListForrequest:OrderProduct[] = [];
        order.orderProductList?.forEach((orderProduct=> {
           const orderProductElement:OrderProduct= {
              productId:orderProduct.productId,
              quantity:orderProduct.quantity,
              packageName:orderProduct.packageName,
              price:orderProduct.price,
              isPreorder:orderProduct.isPreorder,
              available:orderProduct.available
            }
            orderProductListForrequest.push(orderProductElement);
        }))
        this.getOrderedStoreProductsRequest = {
            orderProductList: orderProductListForrequest
        };

        this._apiRequestService
            .sendRequest<GetOrderedStoreProductsRequest, GetOrderedStoreProductsResponse>(
                this.storeBackendUrl,
                true,
                this.getOrderedStoreProductsRequest,
                true,
                this.GET_ORDERED_STOREPRODUCTS_URI
            )
            .subscribe((response: GetOrderedStoreProductsResponse) => {
                this.storeOrderedProductsList = response.storeOrderedProductList as StoreOrderedProduct[];
                this.order = order;
                this.isProductScreenVisible = true;
                console.log(order);
            });

    }

    logout() {
        sessionStorage.clear();
        void this._router.navigateByUrl('/');
    }

    performFilter(filterBy: string): OrderDetails[] {
        filterBy = filterBy.toLocaleLowerCase();
        if (this.displayAllOrders) {
            this.actualFilteringOrders = this.orders;
        } else if (this.displayFinished) {
            this.actualFilteringOrders = this.finishedOrders;
        } else if (this.displayUnFinished) {
            this.actualFilteringOrders = this.unfinishedOrders;
        } else if (this.displayPreorders) {
            this.actualFilteringOrders = this.preOrderedOrders;
        }
        return this.actualFilteringOrders.filter(
            (order: OrderDetails) =>
                order.order?.orderReference?.toLocaleLowerCase().includes(filterBy) ||
                order.lastName?.toLocaleLowerCase().includes(filterBy) ||
                order.customerEmail?.toLocaleLowerCase().includes(filterBy) ||
                order.firstName?.toLocaleLowerCase().includes(filterBy) ||
                order.insertedTimestampp?.toLocaleLowerCase().includes(filterBy) ||
                order.status?.toLocaleLowerCase().includes(filterBy) ||
                order.orderDetailReference?.toLocaleLowerCase().includes(filterBy)
        );
    }

    setBackWholeOrder() {
        this.orders = JSON.parse(sessionStorage.getItem('orders') as string) as OrderDetails[];
    }

    unfinished() {
        this.displayUnFinished = true;
        this.displayFinished = false;
        this.displayAllOrders = false;
        this.displayPreorders = false;
        this.filteredOrder = this.unfinishedOrders;
    }

    allOrders() {
        this.displayUnFinished = false;
        this.displayFinished = false;
        this.displayAllOrders = true;
        this.displayPreorders = false;
        this.filteredOrder = this.orders;
    }

    finished() {
        this.displayUnFinished = false;
        this.displayFinished = true;
        this.displayAllOrders = false;
        this.displayPreorders = false;
        this.filteredOrder = this.finishedOrders;
    }

    preorders() {
        this.displayUnFinished = false;
        this.displayFinished = false;
        this.displayAllOrders = false;
        this.displayPreorders = true;
        this.filteredOrder = this.preOrderedOrders;
    }

    sendDataToGenerateExcel() {
        let idList: Array<number> = [];
        let counter = 0;
        this.filteredOrder.forEach((filteredOrder) => {
            filteredOrder.orderProductList!.forEach((orderProduct) => {
                idList.push(orderProduct.productId!);
            });

            this.getProductRequest = {
                productIdList: idList
            };
            this._apiRequestService
                .sendRequest<GetProductRequest, GetProductResponse>(
                    this.storeBackendUrl,
                    true,
                    this.getProductRequest,
                    true,
                    this.GET_ORDEREDPRODUCTS_URI
                )
                .subscribe((response: GetProductResponse) => {
                    const productResponse = response as GetProductResponse;

                    this.productList = productResponse.storeProducts as Product[];
                    let title: string[] = [];
                    this.productList.forEach((product) => {
                        title.push(product.title!);
                    });
                    this.exportToExcelOrder?.push(
                        new OrderExportToExcel(
                            filteredOrder.id,
                            filteredOrder.customerAddress,
                            filteredOrder.customerEmail,
                            filteredOrder.customerSettlement,
                            filteredOrder.customerZipCode,
                            filteredOrder.customerPhone,
                            filteredOrder.companyName,
                            filteredOrder.firstName,
                            filteredOrder.lastName,
                            filteredOrder.deliveryAddress,
                            filteredOrder.deliveryOption,
                            filteredOrder.deliverySettlement,
                            filteredOrder.deliveryZipCode,
                            filteredOrder.customerOrderReference,
                            filteredOrder.paymentOption,
                            filteredOrder.status,
                            filteredOrder.taxNumber,
                            filteredOrder.totalPrice,
                            filteredOrder.insertedTimestampp,
                            title
                        )
                    );
                    //we need a counter, because normal typescript index gives wrong result because of subscribe indexes are not in order...
                    counter++;

                    if (counter === this.filteredOrder.length) {
                        this.exportToExcel();
                    }
                });
            idList = [];
        });
    }

    exportToExcel() {
        this.exportToExcelRequest = {
            filteredOrder: this.exportToExcelOrder
        };

        this._apiRequestService
            .sendRequestBlob(this.orderBackendUrl, true, this.exportToExcelRequest, true, this.EXPORT_TO_EXCEL_URI)
            .subscribe((blobData: any) => {
                const now = new Date();
                var blob = new Blob([blobData], { type: 'application/vnd.ms-excel' });
                const url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.setAttribute('target', 'blank');
                a.href = url;
                a.download =
                    'order_' +
                    now.toLocaleDateString('en-us', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        timeZone: 'UTC'
                    });
                +'.xls';

                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            });

        this.exportToExcelOrder = [];
    }

    loadConfirmOrderStatusPopup(order: OrderDetails, enterAnimationDuration: string, exitAnimationDuration: string) {
        const isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
        this.dialogConfiger.data = order;
        const popupDialog = this._matDialog.open(ConfirmStatusPopupComponent, {
            data: order,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw'
        });
        const smallDialogSubscription = isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '70vh');
            } else {
                popupDialog.updateSize('calc(50% - 50px)', '');
            }
        });
        popupDialog.afterClosed().subscribe(() => {
            smallDialogSubscription.unsubscribe();
        });
    }

    public onClick(orderdetailReference: string): void {
        this._router.navigateByUrl('order#' + orderdetailReference);
        this.isProductScreenVisible = false;
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 5000);
    }
}
